import {Outlet, useLocation, useNavigate} from "react-router-dom";
import React from "react";

const CurrentYear = new Date().getFullYear();
export default function RootLayout2() {

    const location = useLocation();
    const navigate = useNavigate();
    const handleClick = (link: string) => {
        // Get the current search params
        const searchParams = new URLSearchParams(location.search);
        navigate(`${link}?${searchParams.toString()}`);
    };
    return (
        <div className={"flex flex-col align-middle justify-evenly h-full md:h-full"}>


            <header className={"flex-grow-0 flex-shrink-0"}>
                <div className="sm:mx-auto sm:w-full sm:max-w-sm">
                    <img
                        onClick={() => handleClick("/")}
                        className="mx-auto h-30 w-auto "
                        src="/logo.png"
                        alt="Juhuu Logo"
                    />
                </div>
            </header>




            <main className="flex-grow mx-4 overflow-auto">

                <div className={"max-w-screen-xl m-auto h-100"} style={{
                    //   border: "1px solid red",
                }}>
                    <div
                        className={"grid h-full w-full md:max-w-lg mx-auto shadow-md rounded-xl border-2 overflow-hidden place-items-start justify-items-center p-6 py-8 sm:p-8 lg:p-12"}>

                        <Outlet/>
                    </div>
                    </div>


            </main>
            <footer className="bg-violet-50 rounded-lg shadow m-4  flex-grow-0 flex-shrink-0">
                <div className="w-full mx-auto max-w-screen-xl p-4 md:flex md:items-center md:justify-between">
      <span className="text-sm text-gray-500 sm:text-center ">© {CurrentYear} <a
          href="https://juhuu.app"
          className="hover:underline">JUHUU Bikebox GmbH</a>.
    </span>
                    <ul className="flex flex-wrap items-center mt-3 text-sm font-medium text-gray-500  sm:mt-0">
                        <li>
                            <a href="https://docs.juhuu.app/agb" className="hover:underline me-4 md:me-6">AGB</a>
                        </li>
                        <li>
                            <a href="https://docs.juhuu.app/legal/impressum"
                               className="hover:underline me-4 md:me-6">Impressum</a>
                        </li>
                        <li>
                            <a href="https://docs.juhuu.app/legal/privacy"
                               className="hover:underline me-4 md:me-6">Datenschutzerklärung</a>
                        </li>

                    </ul>
                </div>
            </footer>


        </div>
    );
}
